$mobile:768px;
@mixin flex {
    box-sizing: border-box;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

@mixin mobile {
    @media (max-width: $mobile){
       @content; 
    }
}
@mixin desktop{
    @media (min-width: $mobile){
       @content; 
    }
}