//common spacing
.mt-0{
  margin-top: 0 !important;
}
.mt-20{
  margin-top: 20px !important;
}
.mb-0{
  margin-bottom: 0 !important;
}
.mb-10{
  margin-bottom: 10px !important;
}
.mb-20{
  margin-bottom: 20px !important;
}
.mr-20{
  margin-right: 20px !important;
}
.mt-base{
  margin-top: $custom-space-base !important;
}
.mb-base{
  margin-bottom: $custom-space-base !important;
}
.ml-base{
  margin-left: $custom-space-base !important;
}
.mt-10{
  margin-top: 10px !important;
}
.mr-10{
  margin-right: 10px !important;
}
.ml-10{
  margin-left: 10px !important;
}

.mt-5{
  margin-top: 5px !important;
}
.mb-5{
  margin-bottom: 5px !important;
}
.mr-5{
  margin-right: 5px !important;
}
.ml-5{
  margin-left: 5px !important;
}
.mt-lg{
  margin-top: $custom-space-xl !important;
}
.mb-lg{
  margin-bottom: $custom-space-xl !important;
}
.m-auto{
  margin: auto;
}

.p-base{
  padding: $custom-space-base !important;
}
.pl-0{
  padding-left: 0 !important;
}
.p-lg{
  padding: $custom-space-xl !important;
}
.pt-0{
  padding-top: 0 !important;
}
.pt-10{
  padding-top: 10px !important;
}
.pt-lg{
  padding-top:$custom-space-xl !important;
}
.pb-lg{
  padding-bottom: $custom-space-xl !important;
}
.pt-base{
  padding-top: $custom-space-base !important;
}


