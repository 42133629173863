.risk-tags{
  margin-left: 10px;
  display: inline-block;
  width: 60px;
  text-align: center;
  font-weight: 500;
  color: #ffffff;
  border-radius: 4px;
}
$mobile:768px;
@mixin mobile {
    @media (max-width: $mobile){
       @content; 
    }
}
@include mobile{
  .hide-on-mobile {
    display: none;
  }
}