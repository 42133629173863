@import "mixins";
//common sass
.com-flex{
  display: flex;
  &.direction{
    &_column{
      flex-direction: column;
    }
  }
  &.flex-justify{
    &_center{
      justify-content: center;
    }
    &_between{
      justify-content: space-between;
    }
    &_around{
      justify-content: space-around;
    }
    &_end{
      justify-content: flex-end;
    }
  }
  &.flex-align{
    &_center{
      align-items: center;
    }
  }
}
.com-border-dashed-top{
  border-top: 1px dashed $custom-border-other;
}
.com-border-dashed-bottom{
  margin-bottom: $custom-space-base;
  padding-bottom: 10px;
  border-bottom: 1px dashed $custom-border-other;
}
.com-white-block{
  margin-top:$custom-space-base;
  padding: $custom-space-xl $custom-space-base;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0 0 1px 0 rgba(0,0,0,.04),0 0 1px 0 rgba(0,0,0,.04);
  .block-title{
    margin-bottom:$custom-space-base;
    font-size: 18px;
    font-weight: 500;
  }
}
.com-align{
  &_right{
    text-align: right;
  }
  &_center{
    text-align: center;
  }
}
.com-btn{
  min-width: 90px;
  &-block{
    &.green{
      @include setBtnBlockColor($custom-color-green);
    }
    &.yellow{
      @include setBtnBlockColor($custom-color-orange);
    }
  }
}
.com-w-btn{
  min-width: 80px;
}

.com-hide{
  display: none !important;
}
.com-filter-select-w{
  width: 250px !important;
}
.com-add-modal{
  min-width: 400px;
  .ant-modal-body{
    width: 90%;
    margin:16px auto auto;
  }
  .ant-modal-footer{
    text-align: center;
    .ant-btn{
      min-width: 90px;
    }
  }
}
@media (min-width: 1200px)  {
  .com-add-modal{
    width:45% !important;
  }
}
@media (max-width: 1200px)  {
  .com-add-modal{
    width:65% !important;
  }
}
@media (max-width: 768px)  {
  .com-add-modal{
    width:80% !important;
  }
}