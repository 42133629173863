$unitWidth:40px;
$unitHeight:40px;


.flex {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
}

.border {
  @extend .flex;
  border-color: #eee;
  border-width: 1px;
  border-style: solid;
}

.table_body {
  @extend .flex;
  width: 100%;
  overflow: scroll;

  .table {
    @extend .flex;
    flex-direction: column;
    color: #333333;
    width: 1440px;
    margin: auto;

    .item_center {
      @extend .border;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    .t_header {
      @extend .flex;
      flex-direction: row;
      height: $unitHeight * 3;

      .onse_item {
        @extend .item_center;
        width: $unitWidth;
      }

      .initiatot {
        @extend .item_center;
        width: $unitWidth * 3;
      }

      .barrier {
        @extend .flex;
        flex-direction: column;

        .title {
          @extend .item_center;
          height: $unitHeight;
        }

        .sub_title {
          @extend .flex;
          flex-direction: row;
          flex: 1;

          .desc {
            @extend .item_center;
            width: 6*$unitWidth;
          }

          .category {
            @extend .item_center;
            width: 2*$unitWidth;
          }

          .pfd {
            @extend .item_center;
            width: 2*$unitWidth;
          }
        }
      }

      .result {
        @extend .flex;
        flex-direction: column;

        .title {
          @extend .item_center;
          height: $unitHeight;
        }

        .sub_title {
          @extend .flex;
          flex-direction: row;
          flex: 1;

          .desc {
            @extend .item_center;
            width: 3*$unitWidth;
          }

          .lv {
            @extend .item_center;
            width: $unitWidth;
          }
        }
      }
    }


    .t_content {
      @extend .border;
      flex-direction: row;
      align-items: stretch;

      .onse_item {
        @extend .item_center;
        width: $unitWidth;
        min-height: $unitHeight;
      }


      .result_group {
        @extend .flex;
        flex-direction: column;

        .result {
          @extend .flex;
          flex-direction: row;
          flex: 1;

          .desc {
            @extend .item_center;
            width: 3*$unitWidth;
          }

          .lv {
            @extend .item_center;
            width: $unitWidth;
          }

          .deviation_group {
            @extend .flex;
            flex-direction: column;
            flex: 1;

            .deviation_item {
              @extend .flex;
              flex-direction: row;

              .initiatot_group {
                @extend .flex;
                flex-direction: column;

                .initiatot {
                  @extend .flex;
                  flex-direction: row;

                  .desc {
                    @extend .item_center;
                    width: $unitWidth*3;
                  }

                  .barrier_group {
                    @extend .flex;
                    flex-direction: column;

                    .barrier {
                      @extend .flex;
                      flex-direction: row;
                      min-height: 2*$unitHeight;
                      flex: 1;

                      .desc {
                        @extend .item_center;
                        width: 6*$unitWidth;
                      }

                      .category {
                        @extend .item_center;
                        width: 2*$unitWidth;
                      }

                      .pfd {
                        @extend .item_center;
                        width: 2*$unitWidth;
                      }
                    }
                  }
                }

              }
            }



          }


        }
      }



    }
  }
}
