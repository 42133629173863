@import "variable";
@mixin setBtnBlockColor($background-color:$custom-color-green){
  color: #ffffff;
  background-color:$background-color;
  border-color:$background-color;
  &:hover,&:active,&:focus{
    opacity: .8;
    color: #ffffff;
    background-color: $background-color;
    border-color:$background-color;
  }
}
