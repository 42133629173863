@import "src/sass/variable";

.form-wrapper{
  padding-top: 32px;
  padding-bottom: 20px;
  padding-left: 32px;
  padding-right: 32px;
  background-color: #fff;
  margin-top: 10px;
  
}


.flex{
  display: flex;
  box-sizing: border-box;
  position: relative;
  flex-direction: column;
  flex-shrink:0;
}

.move-view{
  overflow: hidden;
  z-index: 9999;
  position: fixed;
  padding:5px;
  right: 20px;
  bottom: 20px;

  .add-btn{
    background-color: #fff;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    border: #5aa9fa solid 2px;
    font-weight: bold;
    color: #5aa9fa;
    font-size: 18px
  }
}

.chart-view{
  @extend .flex;
  margin-top: 20px;
  min-height: 200px;
  padding-bottom: 100px;
  justify-content: flex-start;
  width: 100%;
  background-color: white;
  padding: 20px;
}

.stream-view{
  @extend .flex;
  min-width: 100%;
  min-height: 100px;
  margin-bottom: 10px;
  flex-direction: row;
  align-items: stretch;
  justify-content: flex-start;
  overflow: scroll;



  .start-event-view{
    @extend .flex;
    min-width: 400px;

    // 起始事件行
    .initiatot-view {
      @extend .flex;
      margin-top: 16px;
      height: 104px;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 1;

      .initiatot-item{
        @extend .flex;
        width: 127px;
        height: 88px;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/working.png');

        .initiatot-info{
          @extend .flex;
          margin-top: 5px;
          margin-left: 8px;
          margin-right: 8px;
          background-color: #fff;
          height: 62px;
          border-radius: 10px;
          justify-content: center;
          // align-items: center;
          text-align: center;
          color: #333;
          font-size: 15px;
          font-weight: 600;
        }

        .initiatot-type{
          align-self: center;
          margin-top: 3px;
          color: #fff;
          font-size: 13px;
        }
      }
    }

  }
  .top_event-view{
    @extend .flex;
    width: 210px;

    .top_event_content{
      @extend .flex;
      flex:1;
      width: 210px;
      min-height: 210px;
      justify-content: center;
      align-items: center;
      .top_event_bg{
        @extend .flex;
        width: 90px;
        height: 90px;
        background-image: url('https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/result.png') ;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 15px;
        justify-content: center;
        align-items: center;
        color: #333;
        font-size: 14px;
      }
    }

  }
  .result_content_view{
    @extend .flex;
    min-width: 400px;

    .result_view{
      @extend .flex;
      margin-top: 16px;
      height: 104px;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-start;
      flex: 1;

      .result-item{
        @extend .flex;
        width: 127px;
        height: 88px;
        margin-left: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        background-image: url('https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/noWorking.png');

        .result-info{
          @extend .flex;
          margin-top: 5px;
          margin-left: 8px;
          margin-right: 8px;
          background-color: #fff;
          flex:1;
          border-radius: 10px;
          justify-content: center;
          align-items: center;
          color: #333;
          font-size: 15px;
          font-weight: 600;
          height: 68px;
          overflow: hidden;
          text-overflow: ellipsis;
          display:inline-block;
        }

        .risk-view{
          @extend .flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          height: 20px;

          .risk-item{
            @extend .flex;
            width: 16px;
            height: 14px;
            justify-content: center;
            align-items: center;
            font-size:8px ;
            margin-right: 3px;
          }

          .risk-normal{
            background-color: #E3E300;
            color: #333;
          }

          .risk-select{
            background-color: #7D0000;
            color: #fff;
          }

        }


      }
    }
  }

   // 保护层
   .barrier-view{
    @extend .flex;
    margin-top: 10px;
    margin-left: 10px;

    .barrier-item{
      @extend .flex;
      width: 118px;
      height:67px;
      border-width: 1px;
      border-color: #a0a0a0;

      .barrier-info{
        @extend .flex;
        flex:1;
        padding: 1px;
        height: 32px;
        text-align: center;
        background-color: #fff;
        justify-content: center;
        align-items: center;
        color: #333;
        font-size: 12px;
      }
      .barrier-state{
        height: 14px;
        background-color: #f00;
        justify-content: center;
        text-align: center;
        color: #fff;
        font-size: 11px;
      }
    }
  }

  .title-view{
    @extend .flex;
    justify-content: center;
    align-items: center;
    color: #666666;
    font-size: 18px;
    border: 1px solid #c8c7cc;
    text-align: center;
    height: 40px;
  }
}

.drag-enter-border{
  border: 2px solid springgreen;
  border-radius: 8px;
}


.svg_view {
  position: absolute;
  top: 0px;
  left: 0px;
}

.barrier-circle-number {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 1px solid #333;
  color: #333;
  text-align: center;
  font: 11px Arial, sans-serif;
}