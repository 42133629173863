
.flex{
    display: flex;
    box-sizing: border-box;
    position: relative;
    flex-direction: column;
    flex-shrink:0;
  }


.node-history{
    @extend .flex;
    height: 100%;
    margin: 20px;

    .flex1{
        @extend .flex;
        flex: 1;
    }
    
    .search-view{
        @extend .flex;
        background-color: #fff;
        flex-direction: row;
        align-items: center;
        color: #333;
        font-size: 14px;
        padding: 20px;

        .filter {
            margin-top: 10px;
        }
        .report{
            margin-top: 30px ;
            margin-left: 10px ;
        }

        .label{
            margin-right: 10px;
        }

        .select{
            width: 180px;
            margin-right: 20px;
        }
    }

    .table-view{
        @extend .flex;
        background-color: #fff;
        margin-top: 20px;
        background-color: #0ff;
        height: 100px;
        
    }

}