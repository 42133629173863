@import "src/sass/base";

.home-container{
  height: 100vh;
  background-color: $custom-background-color;
  .home-header-content{
    padding: 0 $custom-space-xl;
    justify-content: space-between;
    align-items: center;
    line-height: initial;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.1);
    border-bottom: 1px solid $custom-border-other;
    h1{
      margin-bottom: 0;
      font-weight: 400;
      font-size: $custom-font-size-xxxl;
    }
  }
  .header-user-info{
    .message,.message a{
      cursor: pointer;
      color: $custom-text-color-light;
    }
    li{
      margin: auto 10px;
    }
    .vertical-line{
      margin-right:$custom-space-xl;
      height: 20px;
      width: 1px;
      background-color:$custom-border-other;
    }
    .username{
      vertical-align: middle;
    }
  }
  .view-small{
    display:none;
    font-size: 20px;
    color: $custom-color-primary;
  }
}


.home-main-content{
  overflow-y: auto;
  padding: $custom-space-xl;

}
.drawer-menus .ant-drawer-body{
  padding: 0;
}
.small-btn-group{
  margin: 50px 30px;
}

@media screen and (max-width: 767px) {
  .home-container{
    .view-large{
      display: none;
    }
    .view-small{
      display: block;
    }
  }
}
