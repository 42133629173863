@import "variable";

//text color
.text-color-light{
  color:$custom-text-color-light;
}
.text-color-second{
  color:$custom-text-color-secondary;
}
.text-color-primary{
  color: $custom-color-primary;
}
.text-color-danger{
  color: $custom-color-danger;
}
.text-color-green{
  color: $custom-color-green;
}
