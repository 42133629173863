/* 滚动条凹槽的颜色，还可以设置边框属性 */
*::-webkit-scrollbar-track-piece {
    background-color: #f8f8f8;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
  }
  
  /* 滚动条的宽度 */
  *::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  
  /* 滚动条的设置 */
  *::-webkit-scrollbar-thumb {
    background-color: #ddd;
    -webkit-border-radius: 2em;
    -moz-border-radius: 2em;
    border-radius: 2em;
    background-clip: padding-box;
  }
  
  /* 滚动条鼠标移上去 */
  *::-webkit-scrollbar-thumb:hover {
    background-color: #bbb;
  }