@import 'src/sass/variable';
//noinspection CssUnknownTarget

$login-text-color: #ffffff;
//noinspection ALL
.login-container {
    height: 100vh;
    background-image: url("/img/bg2.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .login-button {
        border: 1px solid;
        margin-top: 100px;
        color: $login-text-color;
    }

}

.login-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .login-form {
        margin-top: 10px;
        flex-direction: column;
        min-width: 200px;
        align-self: center;
    }
}

$mobileWidth:768px;
@mixin desktop{
    @media (min-width: $mobileWidth){
        @content; 
    }
}
@mixin mobile{
    @media (max-width: $mobileWidth){
        @content; 
    }
}

@include desktop {
    .login-wrapper {
        background-color: #fff;
        box-sizing: border-box;
        margin-top: 10px;
    }
}
@include mobile {
    .login-container {
        h1 {
            font-size: 32px;
        }
        h2 {
            font-size:26px;
        }
        h3 {
            font-size:20px;
        }
    }
}