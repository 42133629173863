@import "src/sass/variable";

.flex{
  display: flex;
  box-sizing: border-box;
  position: relative;
  flex-direction: column;
  align-items: stretch;
}

.company-page{
  @extend .flex;
  background-color: #fff;
}

.company-info{
  @extend .flex;
  background-color: #fff;
  justify-content: center;
  align-items: stretch; 
  
  .logo{
    width: 100px;
    height: 100px;
  }
  
}

$mobile:768px;
@mixin mobile{
    @media (max-width: $mobile){
       @content; 
    }
}

@include mobile {
  .company-info{
    align-items: center; 
  }
}