$unitWidth: 40px;
$unitHeight: 40px;
$preventionWidth: 748px;
$mitigationWidth: 424px;
$topEventWidth: 300px;
$mainColor: rgba(16, 142, 233, 1);

$current-color-1: rgb(227, 238, 153);

@import 'src/sass/variable';

.flex {
    display: flex;
    box-sizing: border-box;
    flex-direction: column;
    position: relative;
}

.border {
    @extend .flex;
    border-color: #eee;
    border-width: 1px;
    border-style: solid;
}

.item_center {
    @extend .border;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.chart-body {
    @extend .flex;
    width: 100%;
    overflow: scroll;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;

    .chart-button {
        @extend .flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .chart-risk {
        padding-right: 20px;
    }
    .chart-name {
        margin-left: 20px;
        margin-right: 10px;
    }
    .chart-line {
        display: inline-block;
        width: 30px;
        height: 4px;
    }
    .blink-name {
        margin-left: 20px;
        margin-right: 10px;
        animation: blink 2s linear infinite;
        -webkit-animation: blink 2s linear infinite;
        -moz-animation: blink 2s linear infinite;
        -ms-animation: blink 2s linear infinite;
        -o-animation: blink 2s linear infinite;
    }
    .blink-line {
        display: inline-block;
        width: 30px;
        height: 4px;
        animation: blink 2s linear infinite;
        -webkit-animation: blink 2s linear infinite;
        -moz-animation: blink 2s linear infinite;
        -ms-animation: blink 2s linear infinite;
        -o-animation: blink 2s linear infinite;
    }
    @keyframes blink {
        50% {
            color: transparent;
            background-color: transparent;
        }
    }
    .chart {
        @extend .flex;
        padding-top: 16px;
        flex-direction: column;
        color: #333333;
        font-size: 14px;

        .chart_content {
            @extend .flex;
            flex-direction: row;

            .chart_header {
                @extend .border;
                justify-content: center;
                align-items: center;
                height: 70px;
                margin-bottom: 55px;

                .chart_header_text {
                    color: #555;
                    font-size: 24px;
                    font-weight: bold;
                }
            }

            // 保护层
            .barrier-view {
                @extend .flex;
                margin-top: 10px;
                margin-left: 10px;

                .barrier-item {
                    @extend .flex;
                    width: 118px;
                    height: 67px;
                    border-width: 1px;
                    border-color: #a0a0a0;

                    .barrier-info {
                        @extend .flex;
                        flex: 1;
                        padding: 3px;
                        height: 39px;
                        text-align: center;
                        background-color: #fff;
                        justify-content: center;
                        align-items: center;
                        color: #333;
                        font-size: 16px;
                    }
                    .barrier-state {
                        height: 18px;
                        background-color: #f00;
                        justify-content: center;
                        text-align: center;
                        color: #fff;
                        font-size: 14px;
                    }
                }
            }

            .item_view {
                @extend .flex;
                flex: 1;
                justify-content: space-around;
            }

            // 起始事件块
            .initiatot_group {
                @extend .flex;

                // 起始事件行
                .initiatot-view {
                    @extend .flex;
                    padding-bottom: 15px;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: flex-start;

                    .initiatot-item {
                        @extend .flex;
                        width: 127px;
                        height: 88px;
                        background-repeat: no-repeat;
                        background-size: cover;

                        .initiatot-info {
                            @extend .flex;
                            margin-top: 5px;
                            margin-left: 8px;
                            margin-right: 8px;
                            background-color: #fff;
                            height: 62px;
                            border-radius: 10px;
                            justify-content: center;
                            align-items: center;
                            color: #333;
                            font-size: 15px;
                            font-weight: 600;
                        }

                        .initiatot-type {
                            align-self: center;
                            margin-top: 3px;
                            color: #fff;
                            font-size: 13px;
                        }
                    }
                }
            }

            // 顶上事件
            .top_event {
                @extend .flex;
                width: 210px;

                .top_event_view {
                    @extend .flex;
                    flex: 1;
                    width: 210px;
                    justify-content: center;
                    align-items: center;
                    .top_event_bg {
                        @extend .flex;
                        width: 90px;
                        height: 90px;
                        background-image: url('https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/result.png');
                        background-repeat: no-repeat;
                        background-size: cover;
                        padding: 15px;
                        justify-content: center;
                        align-items: center;
                        color: #333;
                        font-size: 14px;
                    }
                }
            }

            // 后果模块
            .result_group {
                @extend .flex;

                .result-view {
                    @extend .flex;
                    padding-bottom: 15px;
                    flex-direction: row;

                    .result-item {
                        @extend .flex;
                        width: 127px;
                        height: 88px;
                        margin-left: 10px;
                        background-repeat: no-repeat;
                        background-size: cover;
                        background-image: url('https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/noWorking.png');

                        .result-info {
                            @extend .flex;
                            margin-top: 5px;
                            margin-left: 8px;
                            margin-right: 8px;
                            background-color: #fff;
                            flex: 1;
                            border-radius: 10px;
                            justify-content: center;
                            align-items: center;
                            color: #333;
                            font-size: 15px;
                            font-weight: 600;
                            height: 68px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: inline-block;
                        }

                        .risk-view {
                            @extend .flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            height: 20px;

                            .risk-item {
                                @extend .flex;
                                width: 16px;
                                height: 14px;
                                justify-content: center;
                                align-items: center;
                                font-size: 8px;
                                margin-right: 3px;
                            }

                            .risk-normal {
                                background-color: #e3e300;
                                color: #333;
                            }

                            .risk-select {
                                background-color: #7d0000;
                                color: #fff;
                            }
                        }
                    }
                }
            }

            .svg_view {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    .foot {
        @extend .flex;
        flex-direction: row;
        align-items: center;
        margin-top: 30px;
        padding: 15px;

        .radio {
            @extend .flex;
            flex-direction: row;

            .radio_button {
                width: 200px;
                text-align: center;
            }
        }

        .updateTime {
            @extend .flex;
            color: #000;
            font-size: 16px;
            margin-left: 20px;
        }
    }

    .legend {
        border-top: 1px solid rgba(#a0a0a0, 0.1);
        .legend-content {
            @extend .flex;
            margin-top: $custom-space-base;
            background-color: rgba(#a0a0a0, 0.08);
            padding: $custom-space-base;
        }
        .example {
            @extend .flex;
            flex-direction: row;
            align-items: center;
            flex: 1;

            .label {
                color: #000;
                font-size: 16px;
                margin-right: 20px;
                font-weight: bold;
            }

            .block {
                width: 100px;
                height: 30px;
                border-radius: 6px;
                margin-left: 5px;
                margin-right: 20px;
            }

            .default {
                @extend .block;
                background-color: rgb(140, 248, 198);
            }

            .not_work {
                @extend .block;
                background-color: rgb(252, 47, 47);
            }
        }

        .line-example {
            @extend .flex;
            flex-direction: row;
            align-items: center;

            .label {
                color: #000;
                font-size: 16px;
                margin-right: 20px;
                font-weight: bold;
            }

            .vname {
                margin-left: 20px;
                margin-right: 10px;
            }

            .vl {
                display: inline-block;
                width: 30px;
                height: 4px;
            }
        }
    }
}
.fullscreen {
    background: #fff;
}
.fullscreen-enabled .fullscreen {
    background: #fff;
    height: 100%;
    @extend .flex;
    justify-content: center;
    align-items: center;
}
