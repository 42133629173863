@import "src/sass/variable";

.chartInfo-content{
    padding: $custom-space-xl;
}
.element-tab-radio{
    .ant-radio-button-wrapper{
        &:first-child{
            border-radius: 16px 0 0 16px;
        }
        &:last-child{
            border-radius: 0 16px 16px 0;
        }
    }
}
$mobile:768px;
@mixin mobile {
    @media (max-width: $mobile){
       @content; 
    }
}
@include mobile{
.element-tab-radio{
    .ant-radio-button-wrapper{
        &:first-child{
            border-radius: 16px 16px 16px 16px;
        }
    }
}
  .hide-on-mobile {
    display: none;
  }
}