


.flex{
    display: flex;
    box-sizing: border-box;
    position: relative;
    flex-direction: column;
    flex-shrink:0;
  }



.formulaInputDiv{
    @extend .flex;

    .tag-list-view{
        @extend .flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 5px;
        background-color: #eee;
        .tag{
            margin-bottom: 5px;
        }
    }

    .action-view{
        @extend .flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
        
        .searchBar{
            
        }
        .input{
            width: 250px;
            flex: 1;
        }

        .addNumBtn{
            margin-left: 5px;
        }
    }
}