@import "src/sass/variable";
.setting-wrapper{
  .setting-item{
    margin: auto;
    text-align: center;
    padding:0 $custom-space-xl $custom-space-xl;
    .ant-row{
      min-width: 400px;
      margin: auto;
      &.ant-row-center{
        width: 40%;
      }
      &.parameter-row{
        width:40%;
      }
    }
    .delete-btn{
      margin-left: 20px;
      font-size: 20px;
      color: $custom-color-danger;
      vertical-align: middle;
    }
  }
  .setting-title{
    margin-bottom: $custom-space-xl;
    font-weight: 500;
    font-size: $custom-font-size-xl;
  }
  .border-top{
    padding-top: $custom-space-xl;
    border-top: 1px dashed $custom-border-other;
  }
  .add-btn-dashed.ant-btn{
    min-width: 100px;
    font-weight: 500;
    border-color: $custom-color-primary;
    color: $custom-color-primary;
    border-radius: 6px;
    width: 60%;
  }
  .option-row,.parameter-row{
    .option-label{
      width: 130px;
    }
    .option-input{
      width: 200px;
    }
  }
}

.setting-tabs{
  position: relative;
  .right-btn-group{
    position: absolute;
    top: 28px;
    right: 20px;
    z-index: 3;
    user-select: none;
  }
}

.flex {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  position: relative;
}


.setting-wrapper {
  padding:$custom-space-xl;
  @extend .flex;
  .pfd {
    @extend .flex;
    min-height: 200px;
    align-items: center;
    .pfd-item {
      @extend .flex;
      flex-direction: row;
      align-items: center;
      .pfd-label {
        margin-left: 15px;
        margin-right: 5px;
      }
      .pfd-label-input {
        width: 150px;
      }
      &.pfd-item{
        margin-top: $custom-space-xl;
      }
    }
  }
  .risk {
    @extend .flex;
    align-items: center;
    .risk-title {
      margin-top: 10px;
      font-weight: 600;
    }
    .risk-item {
      @extend .flex;
      flex-direction: row;
      align-items: center;
      .risk-label {
        @extend .flex;
        text-align: right;
        width: 80px;
      }

      .risk-value-label {
        width: 110px;
        text-align: right;
      }

      .risk-input {
        margin-left: 10px;
        width: 150px;
      }

    }

  }
  .risk-lists{
    text-align: center;
  }
  .risk-table {
    width: 80%;
    margin: auto;
    display: table;
    border-right: 1px solid $custom-border-base;
    border-top: 1px solid $custom-border-base;
    .row {
      display: table-row;
      .item {
        display: table-cell;
        min-width: 90px;
        height: 60px;
        line-height: 2;
        vertical-align: middle;
        font-size: $custom-font-size-xl;
        font-weight: 500;
        text-align: center;
        border-bottom: 1px solid $custom-border-base;
        border-left: 1px solid $custom-border-base;
        .select {
          width: 100%;
          font-size: $custom-font-size-xl;
        }
      }
    }
  }

}
