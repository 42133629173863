@import "src/sass/variable";

.side-content{
  overflow-y: auto;
  max-width: 280px;
  width: 45%;
  .side-menus-title{
    margin-bottom: 0;
    padding: 0 24px;
    font-size: $custom-font-size-xxl;
    font-weight: normal;
    line-height: 60px;
  }
  .side-menu{
    user-select: none;
    li.ant-menu-item{
      margin-bottom: 5px !important;
    }
  }
}
