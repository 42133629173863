.flex{
    display: flex;
    box-sizing: border-box;
    position: relative;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
}

.barrier-alert{
    margin: 20px;
}

.search-view{
    @extend .flex;
    background-color: #fff;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    color: #333;
    font-size: 12px;
    padding: 20px;

    .filter {
        margin-top: 10px;
    }

    .report{
        margin-top: 30px ;
        margin-left: 10px ;
    }

    .label{
        font-size: 12px;
        margin-right: 10px;
    }

    .select{
        width: 200px;
        font-size: 12px;
        background-color: #fff;
    }
}

.table-view{
    @extend .flex;
    background-color: #fff;
    margin-top: 20px;
    // background-color: #0ff;
    height: 100px;
}