@import "variable";

//font size
.font-size-sm{
  font-size: $custom-font-size-sm;
}

.font-size-xl{
  font-size: $custom-font-size-xl;
}
.font-size-xxl{
  font-size: $custom-font-size-xxl;
}
.font-weight-bold{
  font-weight: 500;
}
