.risk-tags {
    margin-left: 10px;
    display: inline-block;
    width: 60px;
    text-align: center;
    font-weight: 500;
    color: #ffffff;
    border-radius: 4px;
}


.risk-tags-animation{

    animation-name: oploop;
    animation-duration: 1s;
    animation-iteration-count: infinite;
}

@keyframes oploop {
    0%{
        opacity: 1;
    }
    50%{
        opacity: 0.2;
    }
    100%{
        opacity: 1;
    }
}
$mobile:768px;
@mixin mobile {
    @media (max-width: $mobile){
       @content; 
    }
}
@include mobile{
  .hide-on-mobile {
    display: none;
  }
}