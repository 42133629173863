@import "variable";
.com-filter-form-wrapper{
  padding-top: 8px;
  margin-right: 8px;
  & > .ant-form-item{
    margin-top: $custom-space-base;
    .ant-input,.ant-select,.ant-picker,.ant-input-number{
      width: 180px;
    }
  }
}