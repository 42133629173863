@import "src/sass/variable";
.wait-item{
  background-color: rgba($custom-background-color,.8) !important;
}
.record-title{
  margin-bottom: 10px;
  font-weight: 500;
  padding-bottom: $custom-space-base;
  border-bottom: 1px dashed $custom-border-other;
}
.record-detail-wrapper{
  .ant-image{
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;
  }
  .label{
    display: inline-block;
    width: 80px;
    color: $custom-text-color-secondary;
  }
}

