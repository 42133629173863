@import "sass/base";

html,body{
  margin: 0;
  padding: 0;
  height: 100%;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root{
  height: 100%;
  background-color: $custom-background-color;
  overflow: auto;
}
ul,li,ol{
  padding: 0;
  margin: 0;
  list-style: none;
}
