@include desktop {
    .risk-dynamic-page{
        @include flex;
        height: 100%;
        background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/home_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .header{
            @include flex;
            flex-direction: row;
            align-items: center;
            height: 62px;

            .left{
                background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/header-left.png");
                flex: 1 0 auto;
                height: 62px;
                background-size: 100% 100%;
            }

            .title-name{
                min-width: 320px;
                text-align: center;
                color: #01C4F7;
                font-size: 50px;
                font-weight: bold;
            }

            .right{
                @include flex;
                background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/header-right.png");
                flex: 1 0 auto;
                height: 62px;
                background-size: 100% 100%;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                padding-right: 20px;

                .out{
                    color:#01C4F7;
                    font-size: 20px
                }
            }
        }

        .content-view{
            @include flex;
            flex-direction: row;
            flex-wrap: wrap;
            align-content: space-between;
        }
        

        .title{
            margin-top: 40px;
            margin-left: 20px;
            height: 30px;
            width: 250px;
            background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/title-bg.png");
            background-size: 100% 100%;
            color: #1ADBFB;
            font-size: 16px;
            justify-content: center;
            padding-left: 45px;
        }

        .hm-table-view{
            @include flex;
            flex: 0 0 28%;
            margin-top: 40px;
            margin-left: 20px;
            background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/table-bg-a.png");
            background-size: 100% 100%;
        }

        .num-view{
            @include flex;
            flex: 0 0 40%;
            margin-top: 60px;
            padding: 20px;
            justify-content: flex-end;

            .num-view-line1{
                @include flex;
                flex-direction: row;
                margin-bottom: 40px;
            }
            .num-view-line2{
                @include flex;
                flex-direction: row;
                margin-bottom: 20px;
            }

            .color1{
                background: linear-gradient(0deg, #38A0D6 0%, #6DCDE6 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            
            .color2{
                background: linear-gradient(0deg, #9837DD 0%, #E23AF5 100%);               
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            
            .color3{
                color: #F7012A;
            }

            .num-item{
                @include flex;
                flex: 1;
                align-items: center;

                .num-text{
                    font-size: 45px;
                    font-family:黑体;
                    font-weight: 100;
                }

                .label-text{
                    background: linear-gradient(0deg, #38A0D6 0%, #6DCDE6 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 12px;
                }
            }

            .tip-view{
                position: absolute;
                top: 30px;
                left: 20px;
                right: 20px;
                bottom: 0px;
                padding: 20px;
                background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/home_center_bg.png");
                background-size: 100% 100%;
                .text{
                    color: #ffffffcc;
                    font-size: 14px;
                    margin-bottom: 2px
                }
            }
        }

        .center-table-view{
            @include flex;
            flex: 0 0 40%;
            margin-top: 36px;
            margin-left: 10px;
            background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/table-bg-a.png");
            background-size: 100% 100%;
        }

        .title-view{
            @include flex;
            flex-direction: row;
            align-items: center;
            margin-top: 40px;
            justify-content: space-around;
            .center-title{
                @include flex;
                margin-left: 20px;
                height: 30px;
                width: 200px;
                background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/title-bg.png");
                background-size: 100% 100%;
                color: #1ADBFB;
                font-size: 16px;
                justify-content: center;
                padding-left: 45px;
            }


            .ant-select-single .ant-select-selector .ant-select-selection-item {
                line-height: 20px !important;
                color: #ffffffaa;
            }

            .ant-select-selector {
                background-color: #00000000;
                border-color: #6CCEE6;
                height: 22px !important;
                border-radius: 11px !important;
            }

            .ant-select-selection-placeholder{
                line-height: 20px !important;
                color: #ffffffaa;
            }
            .ant-select-arrow {
                color: #407BEE;
            }
        }

        .table{
            @include flex;
            width: 25vw;
            margin-top: 20px;
            margin-left: 16px;
            margin-right: 16px;

            .rowClass-single{
                color: #fff;
            }
            .rowClass-double{
                color: #fff;
                background: rgba($color: #0078FF, $alpha: 0.12) !important;
            }
            //修改表头文字、背景颜色  
            .ant-table-thead > tr >th{
                color: #14ADD0;
                background: rgba($color: #0078FF, $alpha: 0.12) !important;
                font-size: 12px;
                border: 0 !important;
            }

            .ant-table{
                background: rgba($color: #fff, $alpha: 0) !important;
            }

            //去除鼠标悬浮的背景色  
            .ant-table-tbody > tr > td {
                font-size: 10px;
                border: 0 !important;
                background: rgba(255,255,255,0) !important;
            }
            //鼠标悬浮的背景色半透明
            .ant-table-tbody > tr:hover{
                background: rgba(255,255,255,0.5) !important;
            }
        }

        .echarts-class{
            margin-top: 20px;
        }

    }
}