@import "src/sass/variable";

.message-content{
  .ant-row{
    width: 85%;
  }
  .label{
    display: inline-block;
    min-width: 80px;
    color: $custom-text-color-light;
    &:after{
      content: '：';
    }
  }
  .ant-btn-link span{
    text-decoration: underline;
  }
  .ant-col{
    margin-bottom: 10px;
    span:nth-of-type(2){
      display: inline-block;
      min-width: 100px;
    }
  }
}
.tips-modal.ant-modal-confirm{
  .ant-modal-confirm-body{
    text-align: center;
    font-weight: 500;
    font-size: $custom-font-size-xxl;
    .anticon-info-circle{
      display: none;
    }
    .ant-modal-confirm-content{
      margin-left: 0;
    }
  }
  .ant-modal-confirm-btns{
    margin-top: 30px;
    text-align: center;
    float: initial;
    button{
      min-width: 90px;
      border-radius: 20px;
    }
  }
}

.record-form{
  margin-top: -10px;
}