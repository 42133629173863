.flex{
    display: flex;
    box-sizing: border-box;
    position: relative;
    flex-direction: column;
    align-items: stretch;
  }

.node-process-page{
    @extend .flex;
    background-color: #fff;
}

.barrier-alert{
    @extend .flex;

    .search-view{
        @extend .flex;
        background-color: #fff;
        flex-direction: row;
        align-items: center;
        color: #333;
        font-size: 14px;

        .filter {
            margin-top: 10px;
        }

        .report{
            margin-top: 30px ;
            margin-left: 10px ;
        }
        .label{
            margin-right: 10px;
        }

        .select{
            width: 180px;
            margin-right: 20px;
        }

    }

}