//custom define variable
$custom-font-size-sm:12px;
$custom-font-size-base:14px;
$custom-font-size-xl:16px;
$custom-font-size-xxl:18px;
$custom-font-size-xxxl:20px;

//text color
$custom-text-color-light:#898989;
$custom-text-color-secondary:#575757;


//color
$custom-color-primary: #297aff;
$custom-color-green:#3ebb52;
$custom-color-orange:#e07429;
$custom-color-danger: #e02929;
$custom-background-color:#F3F6FB;

//btn color
$custom-btn-add:$custom-color-green;

//border
$custom-border-base:#d9d9d9;
$custom-border-other:#eae8e8;

//space
$custom-space-base:16px;
$custom-space-xl:24px;


