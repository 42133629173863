@include mobile {
    .risk-dynamic-page{
        @include flex;
        height: 100%;
        background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/home_bg.png");
        background-repeat: no-repeat;
        background-size: 100% 100%;

        .header{
            @include flex;
            flex-direction: row;
            height: 44px;
    
            .left{
                flex: 1;
                background-size: 100% 100%;
            }
    
            .title-name{
                text-align: center;
                color: #01C4F7;
                font-size: 32px;
                font-weight: bold;
            }
    
            .right{
                @include flex;
                flex: 1;
                background-size: 100% 100%;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                padding-right: 20px;
    
                .out{
                    color:#01C4F7;
                    font-size: 20px
                }
            }
        }

        .content-view{
            @include flex;
            flex-wrap: nowrap;
            align-content: center;
            margin-top: 30px;
            background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/m_home_bg.png");
            background-repeat: no-repeat;
            background-size: 100% 100%;
        }

        .title{
            @include flex;
            margin-top: 20px;
            margin-left: 20px;
            height: 30px;
            width: 200px;
            background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/title-bg.png");
            background-size: 100% 100%;
            color: #1ADBFB;
            font-size: 16px;
            justify-content: center;
            padding-left: 45px;
        }

        .flex-order-0 {
            order: 0;
        }

        .flex-order-1 {
            order: 1;
        }

        .flex-order-2 {
            order: 2;
        }

        .flex-order-3 {
            order: 3;
        }
        .flex-order-4 {
            order: 4;
        }

        .flex-order-5 {
            order: 5;
        }

        .hm-table-view{
            @include flex;
            background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/table-bg-a.png");
            background-size: 100% 100%;
            margin-top: 20px;
        }

        .num-view{
            @include flex;
            justify-content: flex-end;
            margin-top: 10px;
    
            .num-view-line1{
                @include flex;
                flex-direction: row;
                margin-bottom: 40px;
            }
            .num-view-line2{
                @include flex;
                flex-direction: row;
                margin-bottom: 20px;
            }

            .color1{
                background: linear-gradient(0deg, #38A0D6 0%, #6DCDE6 100%);
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            
            .color2{
                background: linear-gradient(0deg, #9837DD 0%, #E23AF5 100%);               
                background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            
            .color3{
                color: #F7012A;
            }

            .num-item{
                @include flex;
                flex: 1;
                align-items: center;
                margin-top: 20px;
    
                .num-text{
                    font-size: 45px;
                    font-family:黑体;
                    font-weight: 100;
                }

                .label-text{
                    background: linear-gradient(0deg, #38A0D6 0%, #6DCDE6 100%);
                    background-clip: text;
                    -webkit-text-fill-color: transparent;
                    font-size: 12px;
                }
            }
    
            .tip-view{
                position: absolute;
                top: 6px;
                left: 10px;
                right: 10px;
                bottom: 10px;
                padding: 10px;
                background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/home_center_bg.png");
                background-size: 100% 100%;
                .text{
                    color: #ffffffcc;
                    font-size: 12px;
                    margin-bottom: 2px
                }
            }
        }

       .center-table-view{
            @include flex;
            background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/table-bg-a.png");
            background-size: 100% 100%;
            margin-top: 20px;
       }

        .title-view{
            @include flex;
            flex-direction: row;
            align-items: center;
            margin-top: 30px;
            justify-content: space-between;

            .center-title{
                @include flex;
                margin-left: 20px;
                height: 30px;
                width: 200px;
                background-image: url("https://safety-chart.oss-cn-beijing.aliyuncs.com/image/project/title-bg.png");
                background-size: 100% 100%;
                color: #1ADBFB;
                font-size: 16px;
                justify-content: center;
                padding-left: 45px;
            }
            
            .dep-select {
                margin-right: 16px;
            }

            .ant-select-single .ant-select-selector .ant-select-selection-item {
                line-height: 20px !important;
                color: #ffffffaa;
            }

            .ant-select-selector {
                background-color: #00000000;
                border-color: #6CCEE6;
                height: 22px !important;
                border-radius: 11px !important;
            }

            .ant-select-selection-placeholder{
                line-height: 20px !important;
                color: #ffffffaa;
            }
            .ant-select-arrow {
                color: #407BEE;
            }
       }

       .table{
            @include flex;
            width: 92vw;
            margin-top: 20px;
            margin-left: 16px;
            margin-right: 16px;
            margin-bottom: 20px;

            .rowClass-single{
                color: #fff;
            }
            .rowClass-double{
                color: #fff;
                background: rgba($color: #0078FF, $alpha: 0.12) !important;
            }
            //修改表头文字、背景颜色  
            .ant-table-thead > tr >th{
                color: #14ADD0;
                background: rgba($color: #0078FF, $alpha: 0.12) !important;
                font-size: 12px;
                border: 0 !important;
            }

            .ant-table{
                background: rgba($color: #fff, $alpha: 0) !important;
            }

            //去除鼠标悬浮的背景色  
            .ant-table-tbody > tr > td {
                font-size: 10px;
                border: 0 !important;
                background: rgba(255,255,255,0) !important;
            }
            //鼠标悬浮的背景色半透明
            .ant-table-tbody > tr:hover{
                background: rgba(255,255,255,0.5) !important;
            }
    }

        .echarts-class{
            margin-top: 20px;
        }

    }
}